import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScullyLibModule } from '@scullyio/ng-lib';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import {HeaderModule} from './shared/components/header/header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FooterModule} from './shared/components/footer/footer.module';
import {AppBannerModule} from './shared/components/app-banner/app-banner.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CookieConsentModule } from './shared/components/cookie-consent/cookie-consent.module';
import { UrlSerializer } from '@angular/router';
import { TrailingSlashUrlSerializer } from './utils/url-serializer';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ScullyLibModule,
    HttpClientModule,
    HeaderModule,
    NgbModule,
    FooterModule,
    AppBannerModule,
    BrowserAnimationsModule,
    CookieConsentModule,
    ModalModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { 
      provide: UrlSerializer, 
      useClass: TrailingSlashUrlSerializer 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
