import {NgModule} from '@angular/core';
import {ExtraOptions, Routes, RouterModule} from '@angular/router';


const routes: Routes = [
  {
    path: 'confirm-email',
    loadChildren: () => import('./views/auth/confirm-email/confirm-email.module')
      .then(m => m.ConfirmEmailModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./views/auth/reset-password/reset-password.module')
      .then(m => m.ResetPasswordModule)
  },
  {
    path: 'ads',
    loadChildren: () => import('./views/ads/ads.module')
      .then(m => m.AdsModule)
  }, {
    path: 'downloaditax',
    loadChildren: () => import('./views/qrdownload/qrdownload.module').then(m => m.QRDownloadModule)
  },
  {
    path: '',
    loadChildren: () => import('./views/landing-page/landing-page.module')
      .then(m => m.LandingPageModule)
  }
];

const config: ExtraOptions = {
    useHash: false,
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
