import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBannerComponent } from './app-banner.component';



@NgModule({
  declarations: [AppBannerComponent],
  exports: [
    AppBannerComponent
  ],
  imports: [
    CommonModule
  ]
})
export class AppBannerModule { }
